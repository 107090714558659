//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapMutations } from 'vuex';
import PageHeader from '@/components/tools/PageHeaderCustom.vue';
import SearchBox from './components/SearchBox.vue';
import PublicSettingModal from './codes/modal/PublicCodesSetting.vue';
import {
  NameCellRenderer,
  DesCellRenderer,
  CreatedCellRenderer,
  ActionsCellRenderer,
  TemplateCellRenderer,
  PathCellRenderer,
  SecurityCellRenderer,
  PhotoCellRenderer,
  DictionaryCellRenderer
} from './grid';
import { PAGE_STATE, PROJECT_STATUS } from '@/enum';
import * as pubsub from '@/pubsub';
import { urlByAnyData } from '@/utils/util';
import CardView from './card-view/Index.vue';
import DuplicateModal from './modal/DuplicateModal';
import ChangeStatusModal from './modal/ChangeStatusModal';

export default {
  components: {
    PageHeader,
    SearchBox,
    PublicSettingModal,
    DuplicateModal,
    ChangeStatusModal,
    ArchiveModal: () => import('../components/ArchiveModal.vue'),
    ImportModal: () => import('../components/ImportModal.vue'),
    IframeReview: () => import('./iframe-code-list/Index.vue'),
    EditPortalWebsiteRenderer: () => import('./portal-site-editor/Index.vue'),
    ChooseProjectModal: () => import('./modal/ChooseProjectModal.vue'),
    NameCellRenderer,
    DesCellRenderer,
    CreatedCellRenderer,
    ActionsCellRenderer,
    TemplateCellRenderer,
    PathCellRenderer,
    SecurityCellRenderer,
    PhotoCellRenderer,
    DictionaryCellRenderer,
    CardView,
  },
  data() {
    return {
      queryParam: {
        title: '',
        slug: '',
        mode: this.$ls.get('page-mode', 'card')
      },
      loading: false,
      context: { componentParent: this },
      pagination: { current: 1, pageSize: 20, total: 0 },
      selectedPages: [],
      pageArchiveVisible: false,
      pageImportVisible: false,
      dragEnterIndex: 0,
      iframeVisible: false,
      interactedPage: {},
      chooseProjectModalVisible: false,
      projectChoosingActionType: '',
      publicSettingModalVisible: false,
      checkedModalPublic: false,
      isIncludeCodes: false,
      gridApi: null,

      duplicateModalVisible: false,
      openIframeReviewAfterPublic: false,
      changeStatus: {
        visible: false,
        havePublishVersion: true,
        loading: false
      }
    };
  },
  created() {
    const { title, slug, size, page, mode, order } = this.$route.query;
    this.queryParam = {
      title: title || '',
      slug: slug || '',
      mode: mode || this.$ls.get('page-mode', 'card'),
      ...(order && { order })
    };
    this.pagination.pageSize = +size || 20;
    this.pagination.current = +page || 1;
    this.getData();
  },
  computed: {
    ...mapGetters('user', ['userInfo']),
    ...mapGetters('page', ['pages']),
    ...mapGetters('project', ['projectById', 'projects']),
    columnDefs() {
      return [
        {
          rowDrag: params => params.data.state === PAGE_STATE.NONE && !params.data.pinned,
          width: 170,
          field: 'id',
          sortable: true,
          unSortIcon: true,
          lockPosition: true
        },
        {
          headerName: 'project.grid.Photo',
          field: 'photo',
          width: 150,
          lockPosition: true,
          cellRenderer: 'PhotoCellRenderer'
        },
        {
          headerName: 'page.grid.Name',
          field: 'name',
          sortable: true,
          unSortIcon: true,
          cellClass: 'locked-col',
          lockPosition: true,
          cellRenderer: 'NameCellRenderer'
        },
        {
          headerName: 'project.grid.Path',
          field: 'slug',
          width: 120,
          lockPosition: true,
          cellRenderer: 'PathCellRenderer'
        },
        {
          headerName: 'project.grid.Template',
          field: 'type',
          width: 150,
          lockPosition: true,
          cellRenderer: 'TemplateCellRenderer'
        },
        {
          headerName: 'project.grid.Dictionary',
          field: 'dictionary',
          width: 120,
          lockPosition: true,
          cellRenderer: 'DictionaryCellRenderer'
        },
        {
          headerName: 'project.grid.PublishSetting',
          field: 'PublishSetting',
          width: 120,
          lockPosition: true,
          cellRenderer: 'SecurityCellRenderer'
        },
        {
          headerName: 'project.grid.Description',
          field: 'description',
          lockPosition: true,
          cellRenderer: 'DesCellRenderer'
        },
        {
          headerName: 'project.grid.Created',
          field: 'created',
          width: 120,
          sortable: true,
          unSortIcon: true,
          lockPosition: true,
          cellRenderer: 'CreatedCellRenderer'
        },
        {
          headerName: 'project.grid.Actions',
          field: 'actions',
          width: 135,
          lockPosition: true,
          pinned: 'right',
          cellRenderer: 'ActionsCellRenderer'
        }
      ];
    },
    includeSiteOpts() {
      return {
        relation: 'project_site_opts',
        scope: {
          where: {
            or: [{ status: 'DRAFT' }, { published: true }]
          },
          fields: {
            id: true,
            version: true,
            status: true,
            is_draft_edited: true
          }
        }
      };
    },
    filter() {
      const { title, slug, order } = this.queryParam;
      let where = {
        ...(title && { name: { ilike: `%${title}%` } }),
        ...(slug && { or: [{ slug: { ilike: `%${slug}%` } }, { id: parseInt(slug) }] }),
        pinned: null,
        status: 'ACTIVE'
      };
      const skip = (this.pagination.current - 1) * this.pagination.pageSize;
      const filter = {
        limit: this.pagination.pageSize,
        skip,
        where,
        include: this.includeSiteOpts,
        order: order || 'order_by DESC'
      };
      return filter;
    },
    isSelectedPage() {
      return this.selectedPages?.length ? true : false;
    },
    selectedPageIds() {
      return this.selectedPages.map(page => page.id);
    },
    projectId() {
      return this.$route.params.projectId;
    },
    currentProject() {
      return this.projectById(this.projectId) || {};
    },
    isChangeProjectStatusWarning() {
      return !this.currentProject?.is_public;
    },
    isContainCatalogPage() {
      return this.selectedPages.some(page => page.template === 'CATALOG');
    }
  },
  methods: {
    ...mapMutations('page', ['UPDATE_ORDER']),
    ...mapActions('page', ['loadPages', 'reset', 'addPage', 'updatePageById', 'removePage']),
    ...mapActions('project', ['updateProjectById']),

    async getData() {
      try {
        this.loading = true;
        const pinnedPages = await this.$s.api.page.find(this.projectId, {
          filter: {
            where: {
              pinned: {
                neq: null
              },
              status: 'ACTIVE'
            },
            include: this.includeSiteOpts,
            order: 'pinned DESC'
          }
        });
        let pages = await this.$s.api.page.find(this.projectId, { filter: this.filter });
        const count = await this.$s.api.page.count(this.projectId, this.filter.where);
        // unshift the primary page into top of pages array
        const primaryPage = pages.find(page => page.state === PAGE_STATE.PRIMARY);
        if (primaryPage) {
          pages.splice(pages.indexOf(primaryPage), 1);
          pages.unshift(primaryPage);
        }
        this.reset();
        this.loadPages({ payload: [...pinnedPages, ...pages] });
        this.pagination = { ...this.pagination, total: count?.count };
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    handleGridReady(params) {
      this.gridApi = params.api;
    },
    rowDragText(params) {
      const { id, name } = params.rowNode.data;
      return `${id} - ${name}`;
    },
    getRowClass(params) {
      return params.data.pinned ? 'cell-pinned' : '';
    },
    goToPageCompose() {
      this.$router.push(`/projects/${this.projectId}/pages/compose`);
    },
    openPageImport() {
      this.pageImportVisible = true;
    },
    openArchiveModal() {
      this.pageArchiveVisible = true;
    },
    handleSearch() {
      this.$router.replace({ query: this.queryParam });
    },
    handleReset() {
      this.queryParam = { title: '', slug: '', mode: this.$route.query.mode || '' };
      this.handleSearch();
    },
    changeTitle(value) {
      this.queryParam.title = value;
    },
    changeSlug(value) {
      this.queryParam.slug = value;
    },
    onChangeView(mode) {
      const { query, path } = this.$route;
      this.$ls.set('page-mode', mode);
      this.$router.replace({ path, query: { ...query, mode: mode } });
    },
    handleChangePage({ page, pageSize }) {
      this.pagination = { ...this.pagination, current: page, pageSize };
      this.$router.replace({ query: { ...this.$route.query, page, size: pageSize } });
    },
    handleSelectPage(event, type = 'grid-view') {
      if (type === 'grid-view') {
        let nodes = event.api.getSelectedNodes();
        this.selectedPages = nodes.map(node => node.data);
      } else {
        this.selectedPages = event.data;
      }
    },
    onOpenDuplicatePageModalFromChild(page) {
      this.duplicateModalVisible = true;
      this.interactedPage = page;
    },
    async handleDuplicatePage(pageId, payload) {
      try {
        this.loading = true;
        await this.$s.api.page.duplicatePage(pageId, {
          is_duplicate_codes: payload.isDuplicateCodes
        });
        this.$message.success(`${this.$t('Duplicate page successfully')}`);
        this.getData();
      } catch (error) {
        console.log(error);
        this.$message.error(`${this.$t('Duplicate page failed')}`);
      } finally {
        this.loading = false;
        this.duplicateModalVisible = false;
      }
    },
    async handlePageArchiving({ resolve, reject }) {
      try {
        // check if include the primary page, reject if true
        const primaryPage = this.selectedPages.find(page => page.state === PAGE_STATE.PRIMARY);
        if (primaryPage) {
          this.$message.warning(`${this.$t('Primary page cannot be archived.')}`);
          reject();
          return;
        }
        const ids = this.selectedPages.map(page => page.id);
        const data = {
          ids,
          status: PROJECT_STATUS.ARCHIVE
        };
        await this.$s.api.page.archivePages(data);
        this.$message.success(`${this.$t('Archive successfully')}`);
        this.selectedPages = [];
        this.getData();
        resolve(1);
      } catch (error) {
        this.$notification.error({ message: `${this.$t('Archive failed')}: ${error.message}` });
        reject(error);
      } finally {
        this.pageArchiveVisible = false;
      }
    },
    async handlePageImporting({ url, projectId, resolve, reject }) {
      try {
        await this.$s.api.page.importPageByUrl(url, projectId);
        const subscribePayload = {
          collectionName: 'IMPORT_PAGE',
          method: 'POST'
        };
        this.pageImportVisible = false;
        resolve(1);
        this.$notification.open({
          message: this.$t('Importing... Please wait for a little bit!'),
          duration: 0,
          icon: <a-icon type="smile" style="color: #108ee9" />
        });
        pubsub.subscribe(this.sockets, subscribePayload, response => {
          this.$notification.destroy();
          if (response.status === 'FAILED') {
            this.$notification.error({
              message: this.$t('Importing the page failed.'),
              description: response?.message || ''
            });
          } else {
            this.getData();
            this.$notification.success({
              message: this.$t('Importing the page succeed.')
            });
          }
          pubsub.unSubscribeAll(this.sockets);
        });
      } catch (error) {
        reject(error);
        this.$notification.error({
          message: this.$t('Importing the page failed.'),
          description: error?.message || ''
        });
      }
    },
    async handleChangeStatusFromChild({ type = 'single', pageIds, id, payload }) {
      this.changeStatus.loading = true;
      const successNotifyMessage = {
        true: this.$t('This page is public.'),
        false: this.$t('This page is private.')
      };
      const errorNotifyMessage = {
        true: this.$t('This page public failed.'),
        false: this.$t('This page private failed.')
      };
      try {
        this.loading = true;
        const input = {
          ispublic: payload.isPublic,
          includes: payload.includes
        };
        if (type === 'single') {
          pageIds = [id];
        }
        await Promise.all(
          pageIds.map(id => {
            this.updatePageById({
              id,
              ispublic: payload.isPublic
            });
            return this.$s.api.page.editPageById(this.projectId, id, input);
          })
        );
        if (payload.includes.includes('project')) {
          this.updateProjectById({ id: this.projectId, is_public: payload.isPublic });
        }
        this.$notification.success({ message: successNotifyMessage[payload.isPublic] });
        if (this.openIframeReviewAfterPublic) {
          this.openIframeReviewAfterPublic = false;
          if (this.iframeVisible) {
            this.$refs.editWebsite.initData();
          }
          this.handleToggleIframeReviewFromChild(pageIds[0], true);
        }
        this.changeStatus.havePublishVersion = true;
      } catch (error) {
        this.$notification.error({ message: errorNotifyMessage[payload.isPublic] });
        throw new Error(error.message);
      } finally {
        this.loading = false;
        this.changeStatus.loading = false;
        this.changeStatus.visible = false;
      }
    },
    async handlePinToTopFromChild(data) {
      try {
        const changeStatus = data.pinned ? false : true;
        await this.$s.api.page.pinningPage(data.id, changeStatus);
        this.$message.success(`${this.$t('Successful')}`);
        this.getData();
      } catch (error) {
        console.log(error);
        this.$message.error(`${this.$t('Failed')}`);
      }
    },
    async handlePageExportFromChild(page) {
      try {
        await this.$s.api.page.exportPageById(page.id, { includes: ['codes'] });
        const subscribePayload = {
          collectionName: 'EXPORT_PAGE',
          method: 'GET',
          modelId: page.id
        };
        this.$notification.open({
          message: this.$t('Exporting... Please wait for a little bit!'),
          duration: 0,
          icon: <a-icon type="smile" style="color: #108ee9" />
        });
        pubsub.subscribe(this.sockets, subscribePayload, response => {
          this.$notification.destroy();
          if (response.status === 'FAILED') {
            this.$notification.error({
              message: this.$t('Exporting the page failed.'),
              description: response?.message || ''
            });
          } else {
            const url = urlByAnyData({ data: response?.data, contentType: 'application/json' });
            const a = document.createElement('a');
            a.href = url;
            a.download = page?.name || 'file-name';
            a.click();
            a.remove();
            this.$notification.success({
              message: this.$t('Exporting the page succeed.')
            });
          }
          pubsub.unSubscribeAll(this.sockets);
        });
      } catch (error) {
        console.log(error);
        this.$notification.error({
          message: this.$t(error?.message || 'error')
        });
      }
    },
    async handleChangePrimaryPageFromChild(pageId) {
      try {
        const input = {
          state: 'PRIMARY'
        };
        await this.$s.api.page.editPageById(this.projectId, pageId, input);
        this.$message.success(this.$t('Set as primary successfully.'));
        this.getData();
      } catch (error) {
        throw new Error(error.message);
      }
    },
    async handleProjectExport() {
      try {
        await this.$s.api.project.exportProjectById(this.projectId, {
          includes: ['pages', 'codes']
        });
        const subscribePayload = {
          collectionName: 'EXPORT_PROJECT',
          method: 'GET',
          modelId: this.projectId
        };
        this.$notification.open({
          message: this.$t('Exporting... Please wait for a little bit!'),
          duration: 0,
          icon: <a-icon type="smile" style="color: #108ee9" />
        });
        pubsub.subscribe(this.sockets, subscribePayload, response => {
          this.$notification.destroy();
          if (response.status === 'FAILED') {
            this.$notification.error({
              message: this.$t('Exporting the project failed.'),
              description: response?.message || ''
            });
          } else {
            const url = urlByAnyData({ data: response?.data, contentType: 'application/json' });
            const a = document.createElement('a');
            a.href = url;
            a.download = this.currentProject.name || 'file-name';
            a.click();
            a.remove();
            this.$notification.success({
              message: this.$t('Exporting the project succeed.')
            });
          }
          pubsub.unSubscribeAll(this.sockets);
        });
      } catch (error) {
        console.log(error);
        this.$notification.error({
          message: this.$t(error?.message || 'error')
        });
      }
    },
    rowDragEnter({ overIndex }) {
      this.dragEnterIndex = overIndex;
      this.gridApi.previousRowDatas = this.gridApi.rowModel.rootNode.allLeafChildren.map(
        item => item.data
      );
    },
    async rowDragEnd({ overIndex, node }) {
      try {
        const previousRowDatas = this.gridApi.previousRowDatas;
        const currentRowDatas = this.gridApi.rowModel.rootNode.allLeafChildren.map(
          item => item.data
        );
        let orderBy = 0;
        // WARNING: This function is serve different purpose: TOP-PAGE and Pinned page is alway in TOP position
        const unDraggableOnTopItem = previousRowDatas.filter(
          item => item.state === PAGE_STATE.PRIMARY || item.pinned
        );
        // Return if the drag and drop position is the same
        if (overIndex === this.dragEnterIndex) return;
        // Return if the drop position is belong to the unDraggableOnTopItem
        if (unDraggableOnTopItem.some(item => item.id === previousRowDatas[overIndex].id)) {
          this.$notification.warning({
            message: this.$t('This page is pinned or primary page. You can not drag and drop it.')
          });
          this.gridApi.setRowData(previousRowDatas);
          return;
        }
        // Get target before the row data ORDER is changed by drag and drop
        const targetCodeOrderBy = previousRowDatas[overIndex].order_by;
        const firstCodeOrderBy = currentRowDatas[overIndex + 1]?.order_by || 0;
        const secondCodeOrderBy = currentRowDatas[overIndex - 1]?.order_by || 0;
        if (overIndex === unDraggableOnTopItem.length) {
          // Drag and drop to the first position (after the unDraggableOnTopItem)
          orderBy = targetCodeOrderBy + 0.5;
        } else if (overIndex === this.pages.length - 1) {
          // Drag and drop to the last position
          orderBy = targetCodeOrderBy - 0.5;
        } else {
          // Drag and drop to the middle position
          orderBy = (firstCodeOrderBy + secondCodeOrderBy) / 2;
        }
        // Update the order by
        const editedPage = await this.$s.api.page.editPageById(this.projectId, node?.data?.id, {
          order_by: orderBy
        });
        // Update the order by in store
        this.UPDATE_ORDER({ id: editedPage.id, order_by: orderBy });
        const flashRow = this.gridApi.getDisplayedRowAtIndex(overIndex);
        this.gridApi.flashCells({ rowNodes: [flashRow] });
      } catch (error) {
        console.log(error);
        this.$notification.error({ message: this.$t('Error') });
      }
    },
    handleToggleIframeReviewFromChild(pageId, checked) {
      if (checked) {
        this.interactedPage = this.pages.find(page => page.id === pageId);
      } else {
        this.interactedPage = null;
        this.getData();
      }
      this.iframeVisible = checked;
    },
    async onOpenChangeStatusModelFromChild(page, options) {
      if (!page.ispublic) {
        const totalPublished = await this.$s.api.page.countSiteOpts(page.id, {
          where: { status: 'PUBLISHED' }
        });
        this.changeStatus.havePublishVersion = totalPublished.count > 0;
      } else {
        this.changeStatus.havePublishVersion = true;
      }
      this.changeStatus.visible = true;
      this.interactedPage = page;
      if (options?.openIframeReviewAfterPublic) {
        this.openIframeReviewAfterPublic = options.openIframeReviewAfterPublic;
      }
    },
    handleOpenCopyPageModelFromChild(page) {
      this.handleCopyPageOpening();
      this.selectedPages = [page];
    },
    handleOpenMovePageModelFromChild(page) {
      this.handleMovePageOpening();
      this.selectedPages = [page];
    },
    handleBackToProjectList() {
      // this.$router.push({
      //   name: 'project-list',
      //   params: {
      //     projectId: this.projectId
      //   }
      // });
      this.$router.go(-1);
    },
    handleCopyPageOpening() {
      this.chooseProjectModalVisible = true;
      this.projectChoosingActionType = 'copy';
    },
    handleMovePageOpening() {
      const primaryPage = this.selectedPages.find(page => page.state === PAGE_STATE.PRIMARY);
      if (primaryPage) {
        this.$message.warning(`${this.$t('Primary page cannot be moved.')}`);
        return false;
      }
      this.chooseProjectModalVisible = true;
      this.projectChoosingActionType = 'move';
      return true;
    },
    async handleCopyPagesFromChild({ selectedId, pageIds, resolve, reject }) {
      try {
        await this.$s.api.project.copyPageToAnotherProject(selectedId, pageIds);
        resolve(1);
        this.$notification.success({
          message: this.$t('Copy page to project successfully', {
            name: this.projectById(selectedId)?.name || ''
          })
        });
        this.chooseProjectModalVisible = false;
      } catch (error) {
        reject(error);
        this.$notification.error({
          message: this.$t('Error'),
          description: error.message || 'Has an error!',
          duration: 3
        });
      }
      this.selectedPages = [];
    },
    async handleMovePagesFromChild({ selectedId, pageIds, resolve, reject }) {
      try {
        await this.$s.api.project.movePageToAnotherProject(selectedId, pageIds);
        for (let i = 0; i < pageIds.length; i++) {
          this.removePage({ id: pageIds[i] });
        }
        resolve(1);
        this.$notification.success({
          message: this.$t('Move page to project successfully', {
            name: this.projectById(selectedId)?.name || ''
          })
        });
        this.chooseProjectModalVisible = false;
      } catch (error) {
        reject(error);
        this.$notification.error({
          message: this.$t('Error'),
          description: error.message || 'Has an error!',
          duration: 3
        });
      }
    },
    async updatePublicSetting(payload) {
      this.loading = true;
      this.publicSettingModalVisible = false;
      let includes = [];
      if (this.isIncludeCodes) {
        includes = this.isIncludeCodes ? ['code'] : [];
      }
      if (this.isChangeProjectStatusWarning) {
        includes = includes.concat(['project']);
      }
      const pageIds = this.selectedPages.map(item => item.id);
      this.handleChangeStatusFromChild({
        type: 'multiple',
        pageIds,
        payload: {
          isPublic: payload.ispublic,
          includes
        }
      });
      this.selectedPages = [];
      this.loading = false;
    },
    async handleAlertPublishNewVersion(page) {
      this.getData();
      if (this.changeStatus.visible) {
        this.iframeVisible = false;
        this.changeStatus.loading = true;
        await new Promise(resolve => setTimeout(resolve, 1000));
        this.changeStatus.havePublishVersion = true;
        this.changeStatus.loading = false;
      } else if (!page.ispublic) {
        this.onOpenChangeStatusModelFromChild(page, { openIframeReviewAfterPublic: true });
      }
    },
    async publishPageSettingOptions(page, siteOptsId, input) {
      try {
        this.loading = true;
        this.siteOpts = await this.$s.api.page.publishSiteOpts(page.id, siteOptsId, input);
        this.getData();
        if (page.ispublic) {
          this.$notification.success({ message: this.$t('New version is released!') });
        } else this.$notification.success({ message: this.$t('New version saved!') });
      } catch (error) {
        console.log(error);
        this.$notification.error({ message: this.$t(error.message) });
      } finally {
        this.loading = false;
      }
    }
  }
};
